var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_center" }),
      _c("headbox", {
        attrs: {
          title: "理赔说明",
          bgColor: "#fff",
          customPath: true,
          path: "claimedit",
          query: { active: "b" },
          isborder: "true"
        }
      }),
      _c("div", { staticClass: "centerbox" }, [
        _vm._m(0),
        _c("div", { staticClass: "step_2" }, [
          _c("h1", [_vm._v("【理赔流程】")]),
          _c("p", [_vm._v("第一步：电话报案")]),
          _c("p", [
            _vm._v(
              "拨打0755-22692109联系保险公司或拨打" +
                _vm._s(_vm.GLOBAL) +
                "客服13161806559寻求协助"
            )
          ]),
          _c("p", [_vm._v("第二步：审核材料")]),
          _c("p", [_vm._v("按照保险公司要求，准备理赔材料，提交保险公司审核")]),
          _c("p", [_vm._v("第三步：赔付完成")]),
          _c("p", [_vm._v("保险公司材料核实通过后，发放赔款")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step_1" }, [
      _c("h1", [_vm._v("【保险责任范围】")]),
      _c("p", [
        _vm._v(
          " 本保险分为邮包险和邮包一切险两种。被保险货物遭受损失时，本保险按保险单上订明承保险标的条款规定负赔偿责任。 "
        )
      ]),
      _c("h2", [_vm._v("（一）邮包险")]),
      _c("p", [_vm._v("本保险负责赔偿：")]),
      _c("p", [
        _vm._v(
          " 1．被保险邮包在运输途中由于恶劣气候、雷电、海啸、地震、洪水自然灾害或由于运输工具遭受搁浅、触礁、沉没、碰撞、倾覆、出轨、坠落、失踪或由于失火、爆炸意外事故所造成的全部或部分损失。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 2．被保险人对遭受承保责任内危险的货物采取抢救、防止或减少货损的措施而支付的合理费用，但以不超过该批被救货物的保险金额为限。 "
        )
      ]),
      _c("h2", [_vm._v("（二）邮包一切险")]),
      _c("p", [_vm._v("本保险负责赔偿：")]),
      _c("p", [
        _vm._v(
          " 除包括上述邮包险的各项责任外，本保险还负责被保险邮包在运输途中由于外来原因所致的全部或部分损失。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }